/* 
                Sommaire

    
        AccountMenu.js / ArtisanAccountMenu.js
    1:= menu
        
        AccountOrder.js / ArtisanAccountOrder.js
    2:= commandes

        AccountPassword.js / ArtisanAccountMdp.js
    3:= updateMdp

        AccountDetails.js / ArtisanAccountDetails.js
    4:= details

        AccountAdress.js / ArtisanAccountAdress.js
    5:= adress

        ArtisanAccountJob
    6:= artisanJobs

        SuppressionCompte
    7:= suppression

        ArtisanAccountOrder.js
    8:= commandesArtisan

    7:= responsive version desktop

*/


.account{
    margin: 6rem 0;
}


/* ----------- 1:= menu ------------ */
.menu{
    width: 25%;
    padding: 3rem 0 0 12rem;
}

.menu a{
    font-size: 1rem;
    margin-top: 2rem;
    padding: 1rem;
    margin-right: 20%;
}

.menu a.selected{
    background-color: var(--pink_background_2);
    border-radius: .5rem;
}

.menu svg{
    margin-right: 1rem;
}







/* ----------- 2:= commande ------------ */
.commandes, section.updateMdp, section.details, section.adress, section.suppression{
    width: 65%;
    padding: 0 12rem 10rem 0;
    border-left: 1px solid #BDDEB4;
    font-size: 1.3rem;
}

.commandes article{
    width: 45%;
    margin: auto;
    text-align: center;
    padding-top: 20%;
}

.commandes p{
    margin: 2rem 0;
}

.commandes button{
    width: 100%;
}

.commandes button svg{
    margin-left: 1rem;
}

/* PLUSIEURS COMMANDES USER */
.existCommand h3{
    margin: 2rem 0 3rem 4rem;
}

.existCommand article{
    width: 70%;
    margin: 0 0 0 4rem;
    padding: 0 0 2rem 0;
    justify-content: flex-start;
}

.existCommand article + article{
    border-top: 1px solid #E9E9EB;
    padding-top: 2rem;
}

.existCommand img{
    width: 5rem;
    height: 5rem;
    margin-right: 3rem;
}

.existCommand p{
    margin: .3rem 0 0 0;
    font-size: .8rem;
    text-align: start;
}

.existCommand div p:first-child, .existCommand div p:last-child, .existCommand div+p{
    font-size: 1rem;
}

.existCommand div+p{
    text-decoration: underline;
    margin-left: auto;
    text-underline-offset: 0.3rem;
}









/* ----------- 3:= updateMdp / 4:= details ------------ */
form.updateMdp, form.details, form.adress{
    width: 50%;
    margin-left: 10%;
}

form.details p{
    color: #E4A2BE;
    background-color: #FFDBE8;
    font-family: 'montserratbold', sans-serif;
    border-radius: 50%;
    width: max-content;
    padding: 1.5rem;
    font-size: 2rem;
    margin-bottom: 3rem;
}







/* ----------- 5:= adress ------------ */
form.adress{
    width: 60%;
}







/* ----------- 5:= artisanJobs ------------ */
.artisanJobs{
    width: 70%;
    border-left: 1px solid #BDDEB4;
    padding-left: 3rem;
    margin-bottom: 5rem;
}

.artisanJobs h2{
    margin-bottom: 2rem;
}

.artisanJobs p+p{
    margin: 1rem 0 3rem 0;
}

.artisanJobs span{
    border: 2px solid #E4A2BE;
    padding: 1rem;
    border-radius: .5rem;
}

.artisanJobs div, .commandesArtisan div.contenu{
    border: 2px solid #E9E9EB;
    border-radius: .5rem;
    margin-top: 4rem;
    margin-right: 1rem;
}

.artisanJobs div h2{
    margin: 3rem 0 3rem 2rem;
}

.artisanJobs div table, .commandesArtisan div table{
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.artisanJobs div thead, .commandesArtisan div thead{
    border-top: 1px solid #BDDEB4;
    border-bottom: 1px solid #BDDEB4;
}

.artisanJobs div thead th, .commandesArtisan div thead th{
    padding: 1rem 0;
}

.artisanJobs div tbody td, .artisanJobs div tbody th,
.commandesArtisan div tbody td, .commandesArtisan div tbody th{
    padding: 2rem 2rem 2rem 0;
    width: max-content;
}

.artisanJobs div tbody td, .commandesArtisan div tbody td{
    padding: 2rem 0 2rem 3rem;
}

.artisanJobs div tbody th.price{
    width: 20rem;
}

.artisanJobs div .checkbox {
    display: none;
}

.artisanJobs div .custom-checkbox {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #F6F6F6;
    border: 1px solid #F6F6F6;
    position: relative;
    cursor: pointer;
}

.artisanJobs div .custom-checkbox:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
}

.artisanJobs div .custom-checkbox:after {
    content: '';
    position: absolute;
    display: none;
    left: 0.8rem;
    top: 0.3rem;
    width: 0.6rem;
    height: 1.4rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
}

.artisanJobs div .checkbox:checked + .custom-checkbox {
    background-color: #FFDBE8;
}

.artisanJobs div .checkbox:checked + .custom-checkbox:after {
    display: block;
}

.artisanJobs div .custom-checkbox:hover {
    background-color: white;
    border: 1px solid black;
}

.artisanJobs button {
    margin-top: 3rem;
    position: absolute;
    right: 1rem;
}








/* ----------- 7:= suppression ------------ */
.suppression div{
    padding: 3rem 0 0 5rem;
    width: 70%;
}

.suppression div h2{
    margin-bottom: 2rem;
}

.suppression div p{
    font-size: .9rem;
    margin-top: 2rem;
}

.suppression div p+p{
    margin-bottom: 2rem;
}

.suppression div button{
    width: max-content;
    margin-top: 2rem;
}








/* ----------- 8:= commandesArtisan ------------ */
.commandes.commandesArtisan{
    border-left: none;
    padding: 0;
}

.commandesArtisan div.contenu{
    margin: 0;
    margin-right: 1rem;
    padding-top: 7rem;
    font-size: .9rem;
    min-height: 75vh;
}

.commandesArtisan div.contenu img{
    width: 3.5rem;
    height: 4rem;
}





/* ----------- 7:= Desktop ------------ */
@media screen and (max-width: 1400px){
    .menu{
        padding-left: 8rem;
    }

    .menu a{
        font-size: 1.1rem;
    }

    .commandes, section.updateMdp, section.details, section.adress{
        padding-right: 8rem;
    }

    h2{
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1300px){
    .menu{
        padding-left: 6rem;
    }

    .menu a{
        font-size: 1rem;
    }

    .commandes, section.updateMdp, section.details, section.adress{
        padding-right: 6rem;
        font-size: 1.1rem;
    }

    h2{
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 1200px){
    .menu{
        width: 40%;
    }

    .commandes, section.updateMdp, section.details, section.adress{
        width: 60%;
    }

    .commandes article, form.updateMdp, form.details{
        width: 60%;
    }

    form.adress{
        width: 70%;
    }

    h2{
        font-size: 1.2rem;
    }
}


@media screen and (max-width: 1080px){
    .menu a{
        font-size: .8rem;
    }

    .menu svg{
        width: 24 !important;
        height: 25 !important;
    }

    .commandes article, form.updateMdp, form.details, form.adress{
        width: 80%;
    }

    form.adress div.double{
        flex-direction: column;
    }
}






















@media screen and (max-width: 600px){
    .account{
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
    }

    .menu{
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-left: 1rem;
    }

    .menu li{
        margin: 1.5rem;
    }

    .menu a{
        font-size: .9rem;
        padding: .5rem 1rem;
        margin: 0;
        width: max-content;
    }

    .commandes, section.updateMdp, section.details, section.adress, section.suppression{
        width: 90%;
        margin: auto;
        margin-bottom: 5rem;
        padding: 0;
        border: none;
    }

    .commandes article{
        width: 100%;
        margin: 0;
        flex-wrap: wrap;
    }

    .commandes article div{
        width: 70%;
    }

    .existCommand div+p{
        margin-left: .5rem;
        margin-top: 1rem;
    }

    form.updateMdp, form.details, form.adress{
        width: 90%;
        margin-left: 5%;
        font-size: .9rem;
    }

    form.updateMdp button, form.details button, form.adress button{
        width: max-content;
        margin-top: 3rem;
    }

    form.adress div.double{
        flex-direction: row;
    }

    form.details p{
        margin: auto;
        margin-bottom: 2rem;
    }

    .suppression div{
        padding: 3rem 0;
        width: 90%;
        margin: auto;
    }
}