/* 
                Sommaire

        
    


*/





/* ----------- 1:= Fonts ------------ */
@font-face {
    font-family: 'montserratlight';
    src: url('./assets/fonts/montserratLight/montserrat-light-webfont.eot');
    src: url('./assets/fonts/montserratLight/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/montserratLight/montserrat-light-webfont.woff2') format('woff2'),
         url('./assets/fonts/montserratLight/montserrat-light-webfont.woff') format('woff'),
         url('./assets/fonts/montserratLight/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratbold';
    src: url('./assets/fonts/montserratBold/montserrat-bold-webfont.eot');
    src: url('./assets/fonts/montserratBold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/montserratBold/montserrat-bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/montserratBold/montserrat-bold-webfont.woff') format('woff'),
         url('./assets/fonts/montserratBold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}













/* ----------- 2:= Constantes -------- */
:root{
    --black : #1E1E1E;
    --white : #FFFFFF;
    --gris_liens : #5C5F6A;
    --gris_background : #F6F6F6;
    --gris_background_mockup : #C4C4C4;
    --input_border : #E6E7E8;
    --label_text : #474B57;
    --pink_background : #FFAEAE;
    --pink_background_2: #FFDBE8;
    --green_background: #D5E5D7;
    --green_background_2: #BDDFB4;
    --green_text: #BDDEB4;
    --pink_text: #E4A2BE;
}












/* ----------- 3:= Global css -------- */
*{
    font-family: "montserratlight", sans-serif;
    margin: 0;
    padding: 0;
}

h1, h2, h3,
.landing .section1 article > p + p,
.landing .section5 p{
    font-family: 'montserratbold', sans-serif;
}

button{
    padding: .8rem 1.4rem;
    border-radius: 2rem;
    background-color: var(--black);
    color: var(--white);
    border: 1px solid var(--black);
    font-size: 1.1rem;
    cursor: pointer;
}

a{
    text-decoration: none;
    color: var(--gris_liens);
    cursor: pointer;
}

ul{
    list-style: none;
}

figure img{
    width: 100%;
}

input{
    border: 2px solid var(--input_border);
    padding: 1rem;
    border-radius: .5rem;
    margin: .5rem 0 1.5rem 0;
}

label{
    color: var(--label_text);
}


.column{
    display: flex;
    flex-direction: column;
}


.row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.alignitem_center{
    align-items: center;
}

.justifycontent_spbetween{
    justify-content: space-between;
}

.text_uppercase{
    text-transform: uppercase;
}

.text_capitalize{
    text-transform: capitalize;
}

.text_bold{
    font-weight: 900;
}

.backWhite{
    background-color: var(--white);
}

.backGris{
    background-color: var(--gris_background);
}

.backRose{
    background-color: var(--pink_background);
}

.backRose2{
    background-color: var(--pink_background_2);
}

.backVert{
    background-color: var(--green_background);
}

.backVert2{
    background-color: var(--green_background_2);
}

.backImg{
    background-image: url('./assets/pictures/backgroundPink.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.textGreen{
    color: var(--green_text) !important;
}

.textPink{
    color: var(--pink_text) !important;
}

.messageGreen{
    background-color: #D5E5D7;
    color: #057234;
    padding: 1rem 2rem; 
    width: max-content;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-left: 3rem;
}

.messageGreen svg{
    margin-right: 1rem;
}

button.black.notAble, button.black.notAble:hover{
    color: #999999;
    border-color: #999999;
    cursor: not-allowed;
    background-color: #F0F0F0;
}

button.black:hover{
    color: var(--black);
    border-color: var(--black);
    background-color: var(--white);
}

button.white{
    color: var(--black);
    background-color: var(--white);
}

button.white.notAble, button.white.notAble:hover{
    color: #999999;
    border-color: #F2F2F2;
    cursor: not-allowed;
    background-color: var(--white);
}

button.white:hover{
    color: var(--white);
    background-color: var(--black);
}











/* ----------- 4:= Header ------------ */
.header-bonus {
    background-color: #0E1422;
    color: var(--white);
    padding: 1rem 0;
    text-align: center;
}

.navigationMobile{
    display: none;
}

.header-main{
    margin: 2rem 10rem;
}

.header-logo{
    width: 10%;
    height: 1rem;
    order: 1;
    z-index: 33;
}

header img{
    width: 100%;
}

.header-nav{
    width: 80%;
    order: 2;
}

.header-nav ul{
    width: 70%;
    margin: auto;
    justify-content: space-around;
}

.header-icons{
    width: 10%;
    justify-content: space-around;
    order: 3;
}

header li{
    cursor: pointer;
}

.popup{
    background-color: var(--white);
    border-radius: .3rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 40%;
    left: 42%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    width: 40%;
    min-height: 300px;
    text-align: center;
    padding-top: 3rem;
}

.popup p{
    background-color: var(--white);
    color: var(--black);
}

.popup h2 + p{
    margin-top: 1.5rem;
}

.popup div{
    padding: 2rem 4rem;
}

.popup button{
    width: 40%;
}

.popup span{
    margin-right: .5rem;
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(217, 217, 217, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1000; 
}





#menuToggle{
    display: block;
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    
    z-index: 1;
    
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    
    cursor: pointer;
    
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    
    -webkit-touch-callout: none;
}

#menuToggle span{
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    
    background: #E4A2BE;
    border-radius: 3px;
    
    z-index: 1;
    
    transform-origin: 4px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}


#menuToggle span:first-child{
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2){
    transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #E4A2BE;
}

#menuToggle input:checked ~ span:nth-last-child(3){
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2){
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

#menu{
    position: absolute;
    width: 100vw;
    height: 90vh;
    margin: -100px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    right: -100px;
    
    background: var(--white);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    
    transform-origin: 0% 0%;
    transform: translate(100%, 0);
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: none;
}

#menu.open{
    display: block;
}

#menu li{
    width: 85%;
    padding: 2.5rem 0 1.5rem 0;
    font-size: 1.1rem;
    color: #000;
    font-weight: bolder;
}

#menu li.end{
    margin-top: auto;
    border: none;
}

#menu li a{
    padding: 10px 0;
    color: #000;
    font-weight: bolder;
}

#menu li + li{
    border-top: 1px solid #E4A2BE;
}

#menuToggle input:checked ~ ul{
    transform: none;
    opacity: 1;
}



/* ----------- 6:= Footer ------------ */
footer{
    padding: 6rem 10rem 4rem;
    color: black;
    font-weight: bold;
}

footer .footer{
    width: 100%;
}

footer article:first-child{
    width: 21%;
}

footer .logo{
    width: 4rem;
    margin-bottom: 1rem;
}

footer article:first-child li{
    margin-right: 2rem;
    margin-top: 2rem;
}

footer article:last-child{
    width: 70%;
}

footer article:last-child ul{
    margin-top: 2rem;
}

footer article:last-child li{
    margin-bottom: 1rem;
}



footer .copyright{
    text-align: center;
    margin-top: 4rem;
    padding-top: 2rem;
    color: #999999;
    border-top: 1px solid #F6F6F6;
}








/* ----------- 4:= Version mobile ------------ */

@media screen and (max-width: 600px){
    header, main, footer{
        overflow-x: hidden;
    }
    .header-main{
        margin: 1rem;
    }

    .header-bonus{
        display: none;
        overflow: hidden;
    }

    .header-icons{
        order: 1;
        width: 20%;
    }

    .header-logo{
        width: 35%;
        margin-left: 10%;
        order: 2;
    }

    .header-nav{display: none;}

    .navigationMobile{
        display: block;
    }

    .popup{
        top: 40%;
        margin-left: 8%;
        width: 90%;
        padding-bottom: 3rem;
    }

    .popup div{
        flex-wrap: wrap;
    }

    .popup p{
        width: 90%;
        margin: auto;
    }

    .popup button{
        width: max-content;
        margin: auto;
    }

    .popup button:first-child{
        margin: 2rem auto;
    }

    footer{
        padding: 4rem;
    }

    footer article:first-child p{
        margin: 2rem 0;
    }

    footer .footer, footer article:last-child{
        flex-direction: column;
    }

    footer article:first-child, footer article:last-child{
        width: 90% !important;
    }

    footer article:last-child div{
        margin-left: 2rem;
        margin-top: 3rem;
    }
}















































/* ----------- 6:= headerLanding ------------ */

.headerLanding{
    padding: 2rem 5rem 3rem 12rem;
    font-size: 1.2rem;
}

.headerLanding section{
    width: 80%;
}

.headerLanding img{
    width: 15%;
    height: 15px;
}

.headerLanding li{
    margin-left: 3rem;
}


@media screen and (max-width: 1470px) {
    .headerLanding{
        padding-left: 10rem;
    }

    .headerLanding section{
        width: 75%;
    }
}

@media screen and (max-width: 1210px) {
    .headerLanding{
        font-size: 1rem;
        padding-left: 8rem;
    }

    .headerLanding button{
        padding: 1rem 1rem;
        font-size: .9rem;
    }
}

@media screen and (max-width: 1020px) {
    .headerLanding{
        padding-left: 5rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width: 790px){
    .headerLanding{
        padding: 2rem;
    }

    .headerLanding section{
        width: 80%;
    }

    .headerLanding button{
        margin-left: 1rem;
        padding: .5rem;
    }
}

