/* 
                Sommaire

        legal

    1:= politique / condition
    2:= mention
    3:= responsive version desktop
    4:= version mobile

*/



/* ----------- 1:= politique ------------ */
.politique h2.titre,
.condition h2.titre,
.mention h2.titre{
    font-size: 2.6rem;
    width: 100%;
    text-align: center;
    margin: 4rem 0 5rem 0;
}

.politique .section,
.condition .section,
.mention .section{
    padding: 1rem 15rem 4rem;
    text-align: justify;
}

.politique h3, 
.mention h3{
    margin-bottom: 2rem;
}

.politique .ordonne li,
.condition .ordonne li{
    list-style-type: decimal;
    margin-left: 1rem;
}

.politique .nonOrdonne li{
    list-style-type: disc;
}

.politique .liOrdonne,
.condition .liOrdonne{
    margin-top: 1.5rem;
}

.politique .ordonne + p{
    margin-top: 1.5rem;
}

.condition .liOrdonne ul li{
    list-style-type: disc !important;
    margin-left: 2rem !important;
}

.condition .liOrdonne .sous-titre{
    margin-left: 1.5rem;
}

.condition .liOrdonne .sous-titre +p{
    margin-bottom: 1rem;
}

.condition .liOrdonne h3{
    margin-top: 3rem;
}




/* ----------- 2:= mention ------------ */
.mention p{
    margin-bottom: 1rem;
}

.mention h3{
    margin: 3rem 0 1.5rem;
}

.mention h3:first-child{
    margin-top: 0;
}


/* ----------- 3:= Desktop ------------ */
@media screen and (max-width: 1100px){
    .politique .section,
    .condition .section,
    .mention .section{
        padding: 1rem 10rem 4rem;
    }
}









@media screen and (max-width: 600px){
    .politique .section,
    .condition .section,
    .mention .section{
        padding: 1rem 3rem 4rem;
        text-align: justify;
    }
}