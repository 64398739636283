/* 
                Sommaire

        Concept
    1:= presentation
    2:= description

        Aide
    3:= section

        Repare
    4:= repare

        Rejoindre
    5:= presentation
    6:= description

        Artisans
    7:= renowear
    8:= recherche

        artisanService
    9:= artisanService

        Etape
    10:= etape

        AllArtisan
    11:= noArtisan
    12:= allArtisan

        Assurance
    13:= assuranceDescription



    10:= version desktop

*/



/* ----------- 1:= presentation ------------ */
.concept .presentation, .concept .presentation > *{
    z-index: 99;
}

.concept .presentation article,
.description div{
    width: 35%;
    margin: auto;
    padding: 5rem 0 7rem;
}

.concept .presentation h2,
.description h3{
    font-size: 3.3rem;
    margin-bottom: 2rem;
}

.concept .presentation p,
.description p{
    font-size: 1.2rem;
    text-align: justify;
}










/* ----------- 2:= description ------------ */
.description div{
    padding: 7rem 0;
}

.description p{
    margin-top: 3rem;
}

.description hr{
    border: 1px solid #DFE1E7;
    margin: 7rem auto;
}

.description{
    background-image: 
        url('../assets/pictures/acceuil/deco3.png'),
        url('../assets/pictures/acceuil/boutton.png'),
        url('../assets/pictures/acceuil/ceintre.png'),
        url('../assets/pictures/acceuil/pelote.png'),
        url('../assets/pictures/acceuil/ciseaux.png'),
        url('../assets/pictures/acceuil/aiguilles.png'),
        url('../assets/pictures/acceuil/deco1.png'),
        url('../assets/pictures/acceuil/deco2.png');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 
        0 0,
        100% -3%,
        0 33%,
        93% 23%,
        100% 57%,
        11% 71%,
        100% 100%,
        0 100%;
}













/* ----------- 3:= section ------------ */
.aide .section{
    padding: 10rem;
}
.aide .section h2{
    font-size: 3rem;
    width: 100%;
    text-align: center;
    margin-bottom: 5rem;
}

.aide .section h3{
    font-size: 2rem;
    margin-bottom: 2rem;
}

.aide .accordion{
    background-color: transparent;
    width: 50%;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}








/* ----------- 4:= repare ------------ */
.repare h2.etape, 
.repare .repareService h2, 
.repare .tuto h2{
    font-size: 2rem;
}

.repare .repareService,
.repare .tuto{
    padding: 4rem 10rem;
}

.repare .repareService h2 + p,
.repare .tuto h2 + p{
    font-size: 1.3rem;
    margin-top: 1rem;
}

.repare .repareService article{
   width: 30%;
}

.repare .repareService article div{
    width: 100%;
    height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: .7rem;
    position: relative;
    overflow: hidden;
}

.repare .repareService article:first-child div{
    background-image: url('../assets/pictures/artisans/pic1.png');
}

.repare .repareService article:nth-child(2) div{
    background-image: url('../assets/pictures/artisans/pic2.png');
}

.repare .repareService article:last-child div{
    background-image: url('../assets/pictures/artisans/pic4.png');
}

.repare .repareService form p{
    margin-top: 1.5rem;
    border-radius: 2rem;
    font-family: 'montserratbold', sans-serif;
    font-size: 1.3rem;
    color: var(--black);
    border: none;
    width: max-content;
    padding: .5rem 1rem;
}

.repare .repareService form button{
    position: relative;
    top: 87%;
    height: 13%;
    width: 100%;
    border-radius: 0;
    display: none;
    z-index: 2;
}

.repare .repareService article div:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(246, 246, 246, 0.5); 
    z-index: 1;
}

.repare .repareService form div:hover button{
    display: block;
}

.repare .tuto div{
    width: 50%;
    margin: auto;
    margin-top: 4rem;
}


.repare .tuto + section{
    margin: 3rem 10rem 6rem 10rem;
    border-radius: 1rem;
    padding: 1rem 2rem;
}

.repare .tuto + section article{
    width: 25%;
    padding: 0 .5rem;
}

.repare .tuto + section article p {
    padding-left: 1rem;
}











/* ----------- 5:= presentation ------------ */
.rejoindre .presentation{
    background-image: url('../assets/pictures/BackgroundRejoindre.png');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.rejoindre .presentation p{
    margin-bottom: 5rem;
    margin-top: 3rem;
    font-weight: bold;
    text-align: center;
}












/* ----------- 6:= description ------------ */
.rejoindre .description{
    background-image: 
        url('../assets/pictures/acceuil/deco3.png'),
        url('../assets/pictures/acceuil/boutton.png'),
        url('../assets/pictures/acceuil/ceintre.png'),
        url('../assets/pictures/acceuil/pelote.png'),
        url('../assets/pictures/acceuil/ciseaux.png'),
        url('../assets/pictures/acceuil/aiguilles.png'),
        url('../assets/pictures/acceuil/deco1.png'),
        url('../assets/pictures/acceuil/deco2.png');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 
        0 8%,
        100% -2%,
        0 32%,
        93% 26%,
        100% 55%,
        11% 65%,
        100% 100%,
        0 100%;
    padding-bottom: 0;
}

.rejoindre .description h3:not(.first){
    margin-top: 10rem;
}

.rejoindre .description li{
    margin: 2rem 0;
    font-size: 1.1rem;
}

.rejoindre .description li span{
    font-weight: bolder;
    font-family: 'montserratbold', sans-serif;
}

.rejoindre .description p.rejoindre{
    margin-bottom: 4rem;
}

.rejoindre .adhesion{
    width: 100%;
    border: 1px solid #E4A2BE;
    border-radius: 1rem;
    text-align: center;
}

.rejoindre .adhesion h4{
    font-size: 2.7rem;
    font-weight: bolder;
    margin-bottom: 4rem;
}

.rejoindre .adhesion span{
    font-size: 5.5rem;
    font-family: 'montserratbold', sans-serif;
}

.rejoindre .adhesion p{
    width: 60%;
    margin: 3rem auto 5rem;
}









/* ----------- 6:= renowear ------------ */
.renowear{
    background-image: 
        url('../assets/pictures/artisans/back1.png'),
        url('../assets/pictures/artisans/back2.png'),
        url('../assets/pictures/artisans/back4.png'),
        url('../assets/pictures/artisans/back3.png'),
        url('../assets/pictures/artisans/back5.png');
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 
        10% 30%,
        35% 20%,
        73% 68%,
        60% 10%,
        90% 0;
    height: 40vh;
    padding-bottom: 5rem;
}

.renowear article{
    width: max-content;
    padding-left: 40%;
    padding-top: 10%;
}

.renowear h2{
    width: max-content;
    border-radius: 1.5rem;
    padding: .5rem 1rem;
    font-size: 2rem;
}











/* ----------- 7:= recherche ------------ */
.recherche form{
    width: 70%;
    margin: auto;
}

.recherche input{
    border: 1px solid black;
    padding: 2rem 1rem;
}

.recherche input:first-child{
    width: 50%;
}

.recherche input:last-child{
    width: 35%;
}

.recherche button{
    width: 20%;
    height: max-content;
    padding: 1rem 1.5rem;
    margin-top: 1rem;
}













/* ----------- 9:= artisanService ------------ */
.artisanService section{
    padding: 3rem 15rem 4rem 15rem;
}

.artisanService article{
    width: 45%;
}

.artisanService p{
    width: 80%;
}

.artisanService article.right{
    text-align: right;
}

.artisanService h2{
    font-size: 2rem;
    line-height: 4rem;
    color: #2F3137;
    margin-bottom: 1.5rem;
}

.artisanService h2 span{
    font-family: 'montserratbold', sans-serif;
    border-radius: 2rem;
    padding: .5rem 1rem;
}

.artisanService article.right p{
    margin-left: 20%;
}

.artisanService div{
    width: 30%;
    height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
}

.artisanService section:first-child div{
    background-image: url('../assets/pictures/artisans/pic1.png');
}

.artisanService section:nth-child(2) div{
    background-image: url('../assets/pictures/artisans/pic2.png');
}

.artisanService section:nth-child(3) div{
    background-image: url('../assets/pictures/artisans/pic3.png');
}

.artisanService section:last-child div{
    background-image: url('../assets/pictures/artisans/pic4.png');
}














/* ----------- 10:= etape ------------ */
.etape{
    margin: 0 10rem;
    border-radius: 1rem;
    padding: 4rem 2rem;
}

.etape h3{
    margin-bottom: 1rem;
}

.etape .container{
    margin-top: 2.5rem;
}

.etape article{
    width: 30%;
}

.etape h4{
    font-size: 1.2rem;
    margin-bottom: .7rem;
}

.etape p{
    font-size: .8rem;
}

.etape img{
    width: 72px;
    height: 73px;
    margin: auto;
    margin-right: .7rem;
}













/* ----------- 11:= noArtisan ------------ */
.noArtisan{
    background-image: 
        url('../assets/pictures/acceuil/deco6.svg'),
        url('../assets/pictures/acceuil/deco5.svg'),
        url('../assets/pictures/acceuil/deco7.svg')
    ;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-color: var(--white);
    background-position: 
        5% 50%,
        0 -24rem,
        0 100%;
}

.noArtisan h2, .noArtisan p{
    margin-left: 45%;
    padding-bottom: 15rem;
    width: 50%;
}

.noArtisan h2{
    font-size: 3rem;
    padding: 15rem 1rem 3rem 0;
}













/* ----------- 11:= allArtisan ------------ */
.allArtisan{
    flex-wrap: wrap;
    padding: 2rem 10rem;
}

.allArtisan article{
    width: 48%;
    border: 2px solid #c4c2c2;
    margin-bottom: 2rem;
    border-radius: .5rem;
    padding: 1.5rem 0;
    cursor: pointer;
}

.allArtisan article:hover{
    border: 2px solid #E4A2BE;
}

.allArtisan .pseudo{
    color: #E4A2BE;
    background-color: #FFDBE8;
    font-family: 'montserratbold', sans-serif;
    border-radius: 50%;
    width: max-content;
    padding: 1.5rem;
    font-size: 2.5rem;
    height: max-content;
    margin: 0 1rem;
}

.allArtisan .pseudo + div{
    display: none;
}

.allArtisan .contenu{
    padding: 0 2rem 1rem 1rem;
    width: 100%;
    margin-top: 1rem;
}

.allArtisan .contenu p.name{
    color: #F071A7;
    font-weight: bolder;
    font-size: 1.3rem;
    font-family: 'montserratbold', sans-serif;
}

.allArtisan .contenu .expertise, .allArtisan .contenu .note{
    margin: 1rem 0;
}

.allArtisan .contenu .note{
    margin: 1rem 0 2rem 0;
}

.allArtisan .contenu .note svg{
    margin-right: .5rem;
}

.allArtisan .metier p:first-child{
   margin-right: 1rem;
}

.allArtisan .metier p{
    border: 1px solid #c4c2c2;
    border-radius: .5rem;
    padding: .5rem 1rem;
}












/* ----------- 13:= assuranceDescription ------------ */
.assuranceDescription h3.titre{
    width: max-content;
    margin: auto;
    margin-top: 5rem;
    text-align: center;
}

.assuranceDescription img{
    margin-left: 45%;
    margin-top: 2rem;
}


.assuranceDescription article{
    width: 80%;
    margin: 4rem auto;
    text-align: justify;
}

.assuranceDescription ul{
    list-style: disc;
}

.assuranceDescription ul, .assuranceDescription ol{
    padding-left: 2rem;
}

.assuranceDescription ul ul{
    padding-left: 3rem;
}

.assuranceDescription li, .assuranceDescription p{
    margin-top: 1rem;
}

.assuranceDescription table{
    margin: 4rem auto;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid #c4c2c2;
}

.assuranceDescription thead{
    background-color: #d8d8d8;
}

.assuranceDescription thead th, .assuranceDescription tbody td, .assuranceDescription tbody th{
    padding: 1rem;
    border: 1px solid #c4c2c2;
}

.assuranceDescription tbody td, .assuranceDescription tbody th{
    padding-right: 5rem;
}

.assuranceDescription button{
    margin: 7rem 0 5rem 42%;
}













/* ----------- 10:= Desktop ------------ */
@media screen and (max-width: 1180px) {
    .concept .presentation article,
    .description div{
        width: 45%;
    }

    .concept .picto{
        width: 30% !important;
    }
    .aide .accordion{
        width: 60%;
        font-size: 1.1rem;
    }

    .aide .section h2{
        font-size: 2.2rem;
    }

    .aide .section h3{
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 998px){
    .aide .section{
        padding: 7rem 5rem;
    }

    .aide .accordion{
        width: 70%;
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 920px){
    .concept .presentation article,
    .description div{
        width: 55%;
    }
}
















@media screen and (max-width: 600px){
    .concept .presentation article, 
    .description div{
        width: 70%;
    }

    .concept .presentation h2,
    .description h3{
        font-size: 2rem !important;
    }

    .concept .presentation p,
    .description p{
        font-size: 1rem;
    }

    .description{
        background-image: none;
    }

    .renowear{
        background-image: 
            url('../assets/pictures/acceuil/Frame_47.png'),
            url('../assets/pictures/acceuil/Frame_48.png'),
            url('../assets/pictures/acceuil/Frame_49.png');
        background-repeat: no-repeat, no-repeat, no-repeat;
        background-size: 10rem, 20rem, 10rem;
        background-position: 
            100% 60%,
            100% 0,
            0 0;
        height: 40vh;
        padding-bottom: 2rem;
    }

    .renowear article{
        padding-left: 20%;
        padding-top: 45%;
    }

    .renowear h2{
        font-size: 1.5rem;
    }

    .recherche form{
        width: 95%;
        flex-wrap: wrap;
    }

    .recherche input{
        padding: 1rem;
    }

    .recherche input:first-child{
        width: 70%;
    }

    .recherche input:last-child{
        width: 25%;
    }

    .recherche button{
        width: max-content;
        margin-top: 1rem;
        align-items: center;
    }

    .recherche button svg{
        padding-top: .3rem;
    }

    .artisanService section{
        padding: 3rem;
        flex-wrap: wrap;
    }

    .artisanService article, .artisanService div{
        width: 90%;
        margin: auto;
    }

    .artisanService div{
        order: 1;
        height: 15rem;
    }

    .artisanService article{
        order: 2;
        text-align: left !important;
        text-align: justify;
    }

    .artisanService h2{
        line-height: 2rem;
        margin-top: 1rem;
    }

    .artisanService article.right p{
        margin: 0;
    }

    .artisanService p{
        width: 100%;
    }

    .allArtisan{
        padding: 2rem;
    }

    .allArtisan article{
        width: 100%;
    }
    
    .allArtisan .contenu .metier{
        display: none;
    }

    .allArtisan .pseudo + div{
        display: block;
        margin-left: 1rem;
    }

    .allArtisan article div:first-child, .allArtisan article div.contenu{
        width: 50% !important;
    }

    .allArtisan .pseudo + div p:first-child{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .noArtisan{
        background-size: 8rem, 15rem, 18rem;
        background-position: 
        3% 30%,
        0 -11rem,
        0 100%;
    }

    .noArtisan h2, .noArtisan p{
        margin-left: 30%;
        width: 60%;
        padding-bottom: 10rem;
    }

    .noArtisan h2{
        padding: 5rem 1rem 3rem 0;
    }

    .rejoindre .description{
        background-image: 
        url('../assets/pictures/acceuil/deco3.png'),
        url('../assets/pictures/acceuil/boutton.png'),
        url('../assets/pictures/acceuil/ceintre.png'),
        url('../assets/pictures/acceuil/pelote.png'),
        url('../assets/pictures/acceuil/ciseaux.png'),
        url('../assets/pictures/acceuil/aiguilles.png'),
        url('../assets/pictures/acceuil/deco1.png'),
        url('../assets/pictures/acceuil/deco2.png');

        background-size: 9rem, 5rem, 5rem, 5rem, 5rem, 5rem, 5rem, 5rem;
        background-position: 
        0 0,
        100% 0,
        0 33%,
        100% 15%,
        100% 57%,
        11% 62%,
        100% 100%,
        0 100%;
    }

    .rejoindre .adhesion{
        padding: 1rem 0;
        width: 90%;
        margin: auto;
    }

    .rejoindre .adhesion h4{
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .rejoindre .adhesion span{
        font-size: 2.2rem;
    }

    .rejoindre .adhesion p{
        width: 80%;
        margin: 2rem auto 3rem;
        font-size: .8rem;
    }

    .repare .repareService,
    .repare .tuto{
        padding: 2rem 3rem;
    }

    .etape{
        margin: 0;
    }

    .etape .container{
        flex-wrap: wrap;
    }

    .etape article{
        width: 90%;
        margin: auto;
        margin-bottom: 2rem;
    }

    .etape img{
        margin: 0;
        margin-right: 1rem;
    }

    .etape h3, .etape span{
        width: 90%;
        margin: auto;
    }

    .repare .repareService h2 + p,
    .repare .tuto h2 + p{
        font-size: 1rem;
    }

    .repare .repareService form{
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .repare .repareService article, .repare .tuto div{
        width: 100%;
    }

    .repare .repareService form p{
        margin-bottom: 2.5rem;
    }

    .repare .tuto + section{
        margin: 2rem 3rem;
        padding: 1rem;
        padding-top: 2.5rem;
        flex-wrap: wrap;
    }

    .repare .tuto + section article{
        width: 45%;
        margin-bottom: 1.5rem;
    }

    .repare .tuto + section article p{
        font-size: .7rem;
    }

    .aide .section{
        padding: 2rem 3rem 6rem 3rem;
    }

    .aide .section h2, .aide .section h3{
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
    }

    .aide .section h3{
        font-size: 1.1rem;
        margin-bottom: 5rem;
    }

    .aide .accordion{
        width: 85%;
        font-size: 1rem;
    }

    .assuranceDescription img{
        margin-left: 35%;
    }

    .assuranceDescription table{
        font-size: .7rem;
        margin: 1rem;
    }

    .assuranceDescription thead th, .assuranceDescription tbody td, .assuranceDescription tbody th{
        padding: .5rem;
    }

    .assuranceDescription button{
        margin: 2rem 0 5rem 25%;
    }
}