/* 
                Sommaire

        
        artisan_signup
    1:= detailsArtisan
    2:= jobArtisan
    3:= reparationArtisan
    4:= abonnement
    5:= artisan_signin

*/

/* ----------- 1:= detailsArtisan ------------ */
.artisan_signup form.detailsArtisan{
    width: 50%;
    margin: auto;
}

.artisan_signup form.detailsArtisan section{
    width: 100%;
}

.artisan_signup form.detailsArtisan div:not(.divInput){
    width: 45%;
}

.artisan_signup .detailsArtisan p, .artisan_signup .detailsArtisan button{
    width: 40%;
    margin: 2rem auto;
}

.artisan_signup .detailsArtisan img{
    width: 1.5rem;
    position: relative;
    left: 90%;
    top: 30%;
    cursor: pointer;
}

.artisan_signup .detailsArtisan button{
    margin: 0;
    margin-left: 30%;
}

.artisan_signup .detailsArtisan .divInput input{
    margin-top: -.5rem;
}







/* ----------- 2:= jobArtisan ------------ */
.artisan_signup .jobArtisan{
    width: 25%;
    margin-left: 30%;
    border-left: 1px solid var(--green_background_2);
    margin-top: 5rem;
    padding: 1rem 0 3rem 4rem;
}

.artisan_signup .job_selection{
    margin-top: 4rem;
    font-size: 1.3rem;
}

.artisan_signup .job_selection span{
    border: 1px solid #E6E7E8;
    width: max-content;
    padding: 1rem;
    border-radius: .5rem;
    margin-bottom: 2rem;
    cursor: pointer;
}

.artisan_signup .job_selection span.selected{
    border: 1px solid #E4A2BE;
}

.jobArtisan button{
    margin-top: 3rem;
}

.jobArtisan button svg{
    margin-left: .5rem;
}












/* ----------- 3:= reparationArtisan ------------ */
.artisan_signup .reparationArtisan{
    width: 50%;
    font-size: 1rem;
    margin-left: 30%;
}

.artisan_signup .reparationArtisan .job_selection{
    flex-direction: row;
    flex-wrap: wrap;
}

.artisan_signup .reparationArtisan span{
    margin-right: 1.5rem;
}








/* ----------- 3:= reparationArtisan ------------ */
.artisan_signup .abonnement{
    padding-bottom: 17rem;
}

.artisan_signup .abonnement div{
    height: 70vh;
    width: 100%;
    background-color: var(--green_background_2);
    background-image: 
        url('../assets/pictures/acceuil/pelote.png'),
        url('../assets/pictures/acceuil/aiguilles.png'),
        url('../assets/pictures/picto/boutton_complet.png');
    background-size: 12rem, 12rem, 12rem;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 
    0 90%,
    85% 10%,
    30% 10%;
    position: relative;
}

.artisan_signup .adhesion{
    background-color: var(--white);
    width: 40%;
    border: 2px solid #E4A2BE;
    border-radius: 1rem;
    text-align: center;
    padding: 8rem 0;
    position: absolute;
    top: 43%;
    left: 30%;
}

.artisan_signup .adhesion h4{
    font-size: 2.7rem;
    font-weight: bolder;
    margin-bottom: 4rem;
}

.artisan_signup .adhesion span{
    font-size: 5.5rem;
    font-family: 'montserratbold', sans-serif;
}

.artisan_signup .adhesion p{
    width: 60%;
    margin: 3rem auto 5rem;
}






/* ----------- 5:= artisan_signup ------------ */
.artisan_signin form{
    width: 25%;
    margin: auto;
    padding: 6rem 0;
}

.artisan_signin p{
    margin: 2rem 0;
}

.artisan_signin button{
    border-radius: .5rem;
}

.artisan_signin input + a{
    margin-bottom: 1rem;
}













@media screen and (max-width: 600px){
    .artisan_signin form{
        width: 90%;
    }

    .artisan_signin button{
        width: max-content;
    }

    .artisan_signin input + a, .artisan_signin button, .artisan_signin p,
    .artisan_signup .detailsArtisan p, .artisan_signup .detailsArtisan button{
        margin-left: auto;
        margin-right: auto;
    }

    .artisan_signup form.detailsArtisan, .artisan_signup .job_selection p{
        width: 90%;
    }

    .artisan_signup form.detailsArtisan section{
        flex-wrap: wrap;
    }

    .artisan_signup form.detailsArtisan div:not(.divInput),
    .artisan_signup .detailsArtisan p, .artisan_signup .detailsArtisan button{
        width: 100%;
    }

    .artisan_signup .jobArtisan{
        width: 80%;
        margin-left: 10%;
        margin-bottom: 3rem;
        padding: 0;
    }

    .artisan_signup .jobArtisan > *{
        margin-left: 3rem;
    }

    .artisan_signup .job_selection{
        font-size: .9rem;
    }

    .artisan_signup .abonnement div{
        background-size: 5rem;
        background-position: 
            0 90%,
            93% 30%,
            4% 34%;
    }

    .artisan_signup .adhesion{
        width: 80%;
        left: 10%;
        top: 50%;
        padding: 4rem 0;
    }

    .artisan_signup .adhesion h4{
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }

    .artisan_signup .adhesion span{
        font-size: 3rem;
    }

    .artisan_signup .adhesion p{
        font-size: .9rem;
        width: 70%;
    }
}  