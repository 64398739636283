/* 
                Sommaire

        Input
    1:= input

        Reparation
    2:= loading
    3:= couture__contenu

        ReparationPic
    4:= inputPics

        CommandRecap
    5:= commandRecap

        Command
    6:= command

        Devis
    7:= devis

        Panier
    8:= panier

*/

/* ----------- 1:= input ------------ */
.input{
    margin: 1.5rem 0 3rem;
}





/* ----------- 2:= loading ------------ */
.loading, .couture__contenu{
    padding: 2rem 10rem;
}

.loading{
    margin-top: 3rem;
    align-items: self-end;
}

.progress-bar {
    width: 55%;
    background-color: #C4C4C4; 
    position: relative;
    margin-top: 1.5rem;
}

.progress-bar .green {
    width: 50%; 
    height: 5px;
    background-color: var(--green_background_2); 
    position: absolute;
    left: 0;
    top: 0;
}

.progress-bar .black {
    width: 50%; 
    height: 2px;
    background-color: #C4C4C4; 
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1px;
}

.repa2 .green{
    width: 100%;
}

.repa2 .black{
    width: 0% !important;
}







/* ----------- 3:= couture__contenu ------------ */
.couture__contenu img.repa{
    width: 35%;
    height: 25rem;
    margin-top: 11rem;
}

.couture__contenu form{
    width: 50%;
    padding: 0;
}

.couture__contenu textarea{
    width: 98%;
    border-color: var(--black);
    border-radius: .3rem;
    padding: 1rem 0 0 1rem;
    margin: 1rem 0 2rem;
    font-size: 1rem;
    outline: none;
}

.couture__contenu textarea:focus{
    border-color: var(--green_background_2);
}

.couture__contenu label{
    color: var(--black) !important;
    font-weight: bolder;
    font-size: 1.2rem;
}

.couture__contenu svg{
    margin-left: .5rem;
}

.couture__contenu button{
    width: max-content;
    align-self: flex-end;
    margin: 4rem 0 6rem;
}

.couture__contenu .broderie div.child{
    width: 45%;
}









/* ----------- 4:= inputPics ------------ */
.inputPics{
    border: 1px solid var(--black);
    height: 30rem;
    margin-top: 2rem;
    border-radius: .5rem;
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid var(--input_border);
    border-radius: 5px;
    color: var(--input_border);
    background-color: white;
    text-align: center;
    transition: all 0.3s;
    margin: 12rem 35%;
    width: max-content;
}

.custom-file-upload:hover {
    background-color: var(--input_border);
    color: white;
}








/* ----------- 5:= commandRecap ------------ */
.commandRecap{
    width: 25%;
    padding: 3rem 2rem;
    border: 1px solid #BDDEB4;
    border-radius: .5rem;
    font-weight: bold;
    position: absolute;
    right: 10rem;
}

.commandRecap h2{
    margin-bottom: 3rem;
}

.commandRecap article{
    margin: .5rem 0;
}

.commandRecap article p:first-child{
    color: #999999;
    font-size: 1.1rem;
}

.commandRecap hr{
    background-color: #E6E7E8;
}

.commandRecap button, .commandRecap hr{
    margin: 2rem 0;
}

.commandRecap button svg{
    margin-left: 1rem;
}

.commandRecap a{
    text-decoration: underline;
    text-align: center;
}










/* ----------- 6:= command ------------ */
.command{
    width: 60%;
    padding-top: 5rem;
    border-top: 1px solid #BDDEB4;
}

.command.delete{
    display: none;
}

.command img{
    width: 5rem;
    height: 5rem;
}

.command p{
    font-size: .9rem;
}

.command span{
    font-weight: bold;
    margin: 0 .5rem;
    font-size: 1.3rem;
}

.command svg{
    padding: 1rem;
    background-color: #FFDBE8;
    border-radius: .3rem;
}

.command .price{
    display: none;
}












/* ----------- 6:= devis ------------ */
.devis{
    padding: 3rem 10rem;
    margin-bottom: 35rem;
}

.devis .devisTitre{
    margin-bottom: 2rem;
}








/* ----------- 6:= panier ------------ */
.panier .command{
    width: 100%;
}

.panier .panier_command{
    width: 60%;
}

.panier .panier_command article{
    border-top: none;
}

.panier .panier_command article:first-child{
    border-top: 1px solid #BDDEB4;
}

.panier_vide{
    text-align: center;
    padding: 10rem 0;
}

.panier_vide p{
    width: 20%;
    margin: 2rem auto 4rem;
}











@media screen and (max-width: 600px){
    .couture__contenu img.repa, .command div + p{
        display: none;
    }

    .loading, .couture__contenu, .devis, .panier_vide{
        padding: 2rem;
    }

    .progress-bar, .command, .couture__contenu form, .panier .panier_command{
        width: 100%;
    }

    .devis{
        margin-bottom: 4rem;
    }

    .devis .devisTitre + section, .command div{
        flex-wrap: wrap;
    }

    .devis .price{
        display: block;
    }

    .command{
        padding-top: 3rem;
    }

    .command div{
        justify-content: start;
    }

    .command div div{
        margin-left: 1rem;
    }

    .command svg{
        position: absolute;
        padding: .5rem;
        width: .5rem;
        height: .5rem;
        top: 46%;
        left: 18%;
        border-radius: 0;
    }

    .commandRecap{
        width: 90%;
        position: inherit;
        margin-top: 4rem;
    }

    .panier_vide p {
        width: 90%;
    }

}