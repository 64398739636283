/* 
                Sommaire

    
        Paiement
    1:= livraison
    2:= commande

        SuccefullOrder
    3:= success

    3:= responsive version desktop
    4:= version mobile

*/



form{
    width: 100%;
    padding: 5rem 0;
}

form h2{
    margin-bottom: 3rem;
}

button{
    border-radius: .5rem;
}



/* ----------- 1:= livraison ------------ */
.livraison{
    width: 65%;
    padding-right: 5%;
    border-right: 2px solid #E4A2BE;
    padding-left: 12rem;
}

.livraison .double div{
    width: 46%;
}

.livraison div.numCarte{
    position: relative;
}

.livraison div.numCarte input{
    width: 95%;
}

.livraison div.numCarte figure{
    width: max-content;
    position: absolute;
    right: 0;
    top: 25%;
}











/* ----------- 2:= commande ------------ */
.commande{
    width: 35%;
    padding-left: 5%;
    padding-right: 12rem;
}

.commande article figure{
    width: 40%;
}

.commande img{
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: .5rem;
}

.commande article button{
    color: var(--noir);
    background-color: var(--blanc);
    border: 1px solid #B6B7BC;
}

.commande .recap{
    margin: 6rem 0 2rem;
}

.commande .recap article{
    margin-bottom: 1.5rem;
    color: #999999;
}

.commande .recap article span+span{
    font-weight: bold;
    color: var(--black);
}

.commande .recap div{
    border: 1px solid #BDDEB4;
    margin: 3rem 0;
}

.commande button[type='submit']{
    width: 100%;
    margin-top: 2rem;
}

.commande button svg{
    margin-left: .5rem;
}






/* ----------- 3:= success ------------ */
.success{
    width: 40%;
    margin: auto;
    text-align: center;
    padding-top: 8rem;
}


.success h2, .success button{
    margin: 2rem 0;
}

.success button svg{
    margin-left: .5rem;
}

.success article{
    text-align: left;
    border: 1px solid var(--pink_text);
    padding: 2rem 3rem;
    margin-bottom: 3rem;
}

.success article p{
    font-weight: bolder;
}

.success ul{
    margin-top: 2rem;
    padding-left: 2rem;
    list-style-image: url('../assets/pictures/order/check.svg');
}

.success li{
    margin-top: 1.5rem;
    padding-left: 1rem;
}




/* ----------- 4:= Desktop ------------ */
@media screen and (max-width: 1300px){
    .livraison{
        padding-left: 9rem;
    }

    .commande{
        padding-right: 9rem;
    }

    .success{
        width: 30%;
    }
}

@media screen and (max-width: 1200px){
    .livraison{
        padding-left: 6rem;
    }

    .commande{
        padding-right: 6rem;
    }

    .commande img{
        width: 30%;
    }
}

@media screen and (max-width: 992px){
    .livraison{
        width: 55%;
    }

    .commande{
        width: 45%;
    }

    .success{
        width: 35%;
    }
}







@media screen and (max-width: 600px){
    form.paiement, .livraison .double{
        flex-wrap: wrap;
    }
    .livraison, .commande{
        width: 85%;
        padding: 0;
        margin: 2rem auto;
        border: none;
    }

    .livraison .double div{
        width: 80%;
    }

    .commande section + button{
        width: 100%;
        margin: 2rem 0;
    }

    .success{
        width: 90%;
    }

    .success ul{
        font-size: .9rem;
    }
}